import React, { FC, useContext, useEffect, useState } from 'react'
import * as pageUtils from './__index.utils'
import PaymentPage from '@components/ui/paymentPage'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { ButtonLink } from '@components/ui/ButtonLink'
import Invoice from '@components/ui/invoice'
import { IInvoice } from '@services/types/invoice'
import { navigate } from 'gatsby'
import apiClient from '@services/api.client'
import { Payments } from '@services/models/payment.model'
import { joinClasses } from '@utils/styles'
import Loader from '@components/ui/loader'
import RealexHpp from '../../../../static/rxp-js'
import axios from 'axios'

type PaymentProps = {}

type stateType = {
	invoice: IInvoice
	origin: string
}

const Payment: FC<PaymentProps> = ({}) => {
	const payments = new Payments()
	const { pageData } = useContext(AppStateContext)
	const [invoice, setInvoice] = useState<IInvoice>({} as IInvoice)
	const [requestOrigin, setRequestOrigin] = useState<string>()
	const [message, setMessage] = useState<string>()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		const state = window.history.state as stateType

		if (state?.invoice) {
			setInvoice(state.invoice)
			setRequestOrigin(state?.origin)
		} else if (state?.origin) {
			navigate(state.origin)
		} else {
			navigate('/')
		}
	}, [])

	const paymentUnsuccessfulRedirect = (code?: string) => {
		if (requestOrigin)
			navigate(requestOrigin, {
				state: {
					isPaymentSuccessful: false,
					code
				}
			})
	}

	const success = async (response: pageUtils.HPPAnswer) => {
		await payments
			.sendPaymentResponse(response)
			.then((res) => {
				if (requestOrigin) {
					navigate(requestOrigin, {
						state: {
							isPaymentSuccessful: true
						}
					})
				} else {
					paymentUnsuccessfulRedirect()
				}
			})
			.catch((error) => {
				console.error(error)
				paymentUnsuccessfulRedirect()
			})
	}

	const fetchPayments = async () => {
		setIsLoading(true)

		await payments
			.getPaymentHPPReponse(invoice.id)
			.then((jsonFromServerSdk) => {
				// Reference for integrating GP
				// https://github.com/globalpayments/rxp-js/blob/master/examples/hpp/process-a-payment-embedded-autoload-callback.html
				RealexHpp.setHppUrl(process.env.PAYMENT_URL)
				RealexHpp.embedded.init(
					pageUtils.BUTTON_ID,
					pageUtils.IFRAME_ID,
					function (answer: pageUtils.HPPAnswer, close: () => void) {
						close()
						if (answer.AUTHCODE) {
							setMessage('Processing transaction')
							//success
							success(answer)
						} else {
							// redirects to the detail page
							paymentUnsuccessfulRedirect(answer.RESULT)
						}
					},
					jsonFromServerSdk
				)
				setTimeout(() => {
					setIsLoading(false)
				}, 2100)
			})
			.catch(() => {
				paymentUnsuccessfulRedirect()
			})
	}

	useEffect(() => {
		if (invoice.id) {
			fetchPayments()
		}
	}, [invoice.id])

	return (
		<>
			<div
				className={joinClasses([
					pageUtils.classes.container,
					pageUtils.classes.centerItem
				])}
			>
				{isLoading && <Loader text={pageData.assets?.loading} />}
				{!message && (
					<div
						className={joinClasses([
							pageUtils.classes.row,
							isLoading ? pageUtils.classes.visibility : ''
						])}
					>
						<div className={pageUtils.classes.billFormContainer}>
							{/* <PaymentPage /> */}
							<iframe
								id={pageUtils.IFRAME_ID}
								style={{ display: 'none' }}
							></iframe>
						</div>
						<div className={pageUtils.classes.invoiceContainer}>
							<h4>{pageData?.assets?.paymentPage_fees_to_pay}</h4>
							<Invoice invoice={invoice} />
							{/* <ButtonLink
								type="button"
								onclick={payInvoice}
								wrapperClassName={pageUtils.classes.buttonWrapper}
								text={pageData?.assets?.paymentPage_submit_and_pay}
							/> */}
						</div>
					</div>
				)}

				{message && (
					<>
						<div>{message}</div>
					</>
				)}
			</div>
		</>
	)
}

export default Payment
