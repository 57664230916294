import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export const IFRAME_ID = 'targetIframe'
export const BUTTON_ID = 'autoload'

export type ClassType = {
	container: string
	row: string
	billForm: string
	buttonWrapper: string
	invoiceContainer: string
	billFormContainer: string
	visibility: string
	centerItem: string
}

export const classes: ClassType = makeClasses({
	container: {
		maxWidth: '1060px',
		margin: 'auto',
		background: Colors.white,
		padding: '40px'
	},
	row: {
		display: 'grid',
		gridTemplateColumns: '60%38%',
		marginRight: '-15px',
		marginLeft: '-15px',
		gap: '2%',
		[Breakpoints.maxWidth('lg')]: {
			gridTemplateColumns: '60%38%'
		},
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr',
			gap: '40px'
		},
		'& h4': {
			padding: '0',
			fontWeight: '700',
			fontSize: '22px',
			margin: '0 0 30px 0',
			color: Colors.darkBlue1
		},
		'& iframe': {
			minWidth: '100%',
			minHeight: '600px'
		}
	},
	billFormContainer: {
		width: '100%',
		[Breakpoints.maxWidth('md')]: {
			order: 2
		},
		'& iframe': {
			borderWidth: '0px !important'
		}
	},
	invoiceContainer: {
		width: '100%',
		[Breakpoints.maxWidth('md')]: {
			order: 1
		}
	},
	buttonWrapper: {
		width: '100%',
		marginTop: '1rem',
		'& button': {
			width: '100%',
			margin: '0'
		}
	},
	visibility: {
		visibility: 'hidden'
	},
	centerItem: {
		'& css-2xfm4d': {
			marginRight: '-20px'
		}
	}
})

export type HPPAnswer = {
	SHIPPING_CODE: string
	SRD: string
	HPP_CARD_TYPE: string
	HPP_ISSUING_COUNTRY_CODE: string
	HPP_COMMERCIAL: string
	HPP_ISSUING_BANK: string
	CVNRESULT: string
	HPP_CUSTOMER_PHONENUMBER_MOBILE: string
	HPP_SHIPPING_STREET3: string
	HPP_SHIPPING_STREET1: string
	PASREF: string
	HPP_SHIPPING_STREET2: string
	MESSAGE: string
	BILLING_CODE: string
	ACCOUNT: string
	AVSPOSTCODERESULT: string
	EXPDATE: string
	AMOUNT: string
	TIMESTAMP: string
	pas_uuid: string
	HPP_BILLING_STREET3: string
	HPP_ADDRESS_MATCH_INDICATOR: string
	HPP_ISSUING_COUNTRY: string
	HPP_BILLING_STREET2: string
	AUTHCODE: string
	HPP_BILLING_STREET1: string
	HPP_BILLING_CITY: string
	HPP_SHIPPING_COUNTRY: string
	CARDDIGITS: string
	AVSADDRESSRESULT: string
	CARDNAME: string
	HPP_SHIPPING_POSTALCODE: string
	HPP_BILLING_POSTALCODE: string
	HPP_BILLING_COUNTRY: string
	BATCHID: string
	SHA1HASH: string
	HPP_SHIPPING_CITY: string
	ORDER_ID: string
	BILLING_CO: string
	HPP_SHIPPING_STATE: string
	HPP_CUSTOMER_EMAIL: string
	HPP_FRAUDFILTER_RESULT: string
	CARDTYPE: string
	RESULT: string
	SHIPPING_CO: string
	MERCHANT_ID: string
}
