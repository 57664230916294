import { PaymentRepository } from '@services/repositories/payment.repository'
import { IPayment } from '@services/types/invoice'
import { Exclude } from 'class-transformer'

export class Payments implements IPayment {
	@Exclude()
	private _repository: PaymentRepository

	constructor() {
		this._repository = new PaymentRepository()
	}

	@Exclude()
	public async getPaymentHPPReponse(id: string): Promise<any> {
		const paymentDetails = await this._repository.getPaymentHPPReponse(id)

		return paymentDetails
	}

	@Exclude()
	public async sendPaymentResponse(responceData: any): Promise<any> {
		const paymentResponse = await this._repository.sendPaymentResponse(
			responceData
		)

		return paymentResponse
	}
}
