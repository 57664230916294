import React, { FC, useContext } from 'react'
import * as pageUtils from './__index.utils'
import { INVOICE_STATUS } from '@services/constants/requests.constant'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { IInvoice } from '@services/types/invoice'

type InvoiceProps = {
	invoice: IInvoice
}

const Invoice: FC<InvoiceProps> = ({ invoice }) => {
	const { pageData } = useContext(AppStateContext)

	const getInvoiceProductDescription = (invoice, item) => {
		if (item.productDescription) {
			return item.description + ' - ' + item.productDescription
		}

		if (invoice.description) {
			return item.description + ' - ' + invoice.description
		}

		return item.description
	}

	const getSortedDetailItems = (items) => {
		let detailItems = items

		detailItems.sort((a, b) => {
			if (a.description < b.description) {
				return -1
			} else if (a.description > b.description) {
				return 1
			} else {
				return 0
			}
		})

		return detailItems
	}

	return (
		<>
			<table className={pageUtils.classes.table}>
				<tbody>
					{invoice?.status === INVOICE_STATUS.calculationOfTheAmount ? (
						<>
							<tr>
								<td>{invoice?.name}</td>
								<td></td>
								<td>{pageData?.assets?.page_requestDetails_analyzing}</td>
							</tr>
						</>
					) : (
						<>
							{!invoice?.detailItems?.length ? (
								<>
									<tr>
										<td>{invoice?.name}</td>
										<td></td>
										<td>{invoice?.totalAmount}$</td>
									</tr>
								</>
							) : (
								<>
									{/* {invoice?.detailItems
										.sort((a, b) => {
											return (
												parseFloat(a.description) - parseFloat(b.description)
											)
										}) */}
									{getSortedDetailItems(invoice?.detailItems).map(
										(detail, index) => (
											<tr key={`${detail.id}-${index}`}>
												<td>{getInvoiceProductDescription(invoice, detail)}</td>
												<td>
													{detail.quantity} x{' '}
													{detail.pricePerUnit?.toFixed(2).replace('.', ',')}$
												</td>
												<td>
													<strong>
														{detail.total?.toFixed(2).replace('.', ',')}$
													</strong>
												</td>
											</tr>
										)
									)}
								</>
							)}
						</>
					)}

					<tr>
						<td>{pageData?.assets?.page_requestDetails_tax_and_other_fee}</td>
						<td></td>
						{invoice?.status === INVOICE_STATUS.calculationOfTheAmount ? (
							<td>{pageData?.assets?.page_requestDetails_coming}</td>
						) : (
							<td>{invoice?.totalTax?.toFixed(2).replace('.', ',')}$</td>
						)}
					</tr>

					<tr className="total">
						<td>{pageData?.assets?.page_requestDetails_total}</td>
						<td></td>
						{invoice?.status === INVOICE_STATUS.calculationOfTheAmount ? (
							<>
								<td>{pageData?.assets?.page_requestDetails_coming}</td>
							</>
						) : (
							<>
								<td>{invoice?.grandTotal?.toFixed(2).replace('.', ',')}$</td>
							</>
						)}
					</tr>
				</tbody>
			</table>
		</>
	)
}

export default Invoice
