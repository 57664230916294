import axios, { AxiosError } from 'axios'
import { AxiosResponse } from '@services/types'
import apiPayment from '@services/api.payment'
import { acquireAccessToken } from '@utils/authConfig'
import { IPayment } from '@services/types/invoice'
import { BaseRepository } from './_miscellaneous.repository'

export class PaymentRepository extends BaseRepository implements IPayment {
	constructor() {
		super()
		this.resource = 'invoices/pay'
	}

	public getPaymentHPPReponse = async (id: string) => {
		try {
			const response: AxiosResponse<any> = await apiPayment.get('', {
				params: {
					resource: this.resource,
					params: this.stringify({
						id
					})
				},
				headers: {
					token: await acquireAccessToken()
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while fetching HPP request: ' + error.message)
			}

			throw error
		}
	}

	public sendPaymentResponse = async (globalPaymentResponce: any) => {
		try {
			const response: AxiosResponse<any> = await apiPayment.post(
				'',
				globalPaymentResponce,
				{
					params: {
						resource: 'invoices/post'
					},
					headers: {
						token: await acquireAccessToken()
					}
				}
			)

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while sending HPP response: ' + error.message)
			}

			throw error
		}
	}
}
