import { makeClasses } from '@utils/styles'

type classes = {
	table: string
}

export const classes: classes = makeClasses({
	table: {
		border: '1px solid #ccc',
		borderCollapse: 'collapse',
		width: '100%',
		'& tr': {
			borderTop: '1px solid #ccc',
			'& td': {
				textAlign: 'right',
				padding: '8px 16px',
				fontSize: '14px',
				':first-child': {
					textAlign: 'left',
					fontWeight: 'bold',
					width: '50%'
				}
			}
		},
		'& tr.total': {
			fontWeight: 'bold',
			fontSize: '18px',
			background: '#e6e7e8',
			td: {
				fontSize: '16px !important'
			}
		}
	}
})
